import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import JobCard from "../../components/JobCard";

const JobsListingSectionContainer = styled.div`
  background-color: ${props => props.theme.colors.offWhite};
  padding: 4rem 1.5rem;
  @media screen and (min-width: ${props => props.theme.breakpoints.md}) {
    padding: 7.7rem 7.2rem;
  }
`;

const JobsListingTitle = styled.h2`
  font-family: ${props => props.theme.fonts.medium};
  margin-bottom: 2rem;
  margin-left: 1rem;
  font-size: 4rem;
  @media screen and (min-width: ${props => props.theme.breakpoints.md}) {
    font-size: 6.5rem;
  }
`;

const ListingContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  @media screen and (min-width: ${props => props.theme.breakpoints.sm}) {
  }
  @media screen and (min-width: ${props => props.theme.breakpoints.md}) {
    margin-top: 6rem;
    margin-bottom: 7rem;
  }
`;

const JobsListing = ({ title, titleColor }) => {
  const queriedJobs = useStaticQuery(jobsQuery);
  const jobs = queriedJobs.allWpJob.nodes;
  return (
    <JobsListingSectionContainer>
      <JobsListingTitle
        css={css`
          color: ${titleColor};
        `}
      >
        {title}
      </JobsListingTitle>
      <ListingContainer>
        {jobs.map(job => (
          <JobCard key={job.databaseId} job={job} />
        ))}
      </ListingContainer>
    </JobsListingSectionContainer>
  );
};

const jobsQuery = graphql`
  query {
    allWpJob {
      nodes {
        databaseId
        title
        uri
        JobPosting {
          fieldGroupName
          intro
        }
      }
    }
  }
`;
export default JobsListing;
